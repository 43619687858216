<template>
  <v-container>
    <v-row>
      <v-col cols="3" xl="2">
        <v-card>
          <v-card-title>
            <v-toolbar-title>测量分组</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="isCreatingNewGroup = true"
                >
                  <v-icon>mdi-layers-plus</v-icon>
                </v-btn>
              </template>
              <span>新建测量分组</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-list nav max-height="calc(100vh - 220px)" class="overflow-y-auto">
            <v-list-item
              v-for="group in groupList"
              :key="group.value"
              link
              :to="getGroupDetailsRouteName(group.value)"
            >
              {{ group.text }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10">
        <router-view></router-view>
      </v-col>
    </v-row>
    <AppDialog
      v-model="isCreatingNewGroup"
      title="创建新的测量分组"
      color="green"
      action-text="创建"
      :loading="isBtnLoading"
      @confirm="createNewGroup"
      @closed="groupCreationDialogClosed"
    >
      <v-text-field
        v-model="newGroupName"
        label="分组名称"
        :rules="fieldRules.newGroupName"
      ></v-text-field>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters, mapActions } from "vuex";
import { createNewGroup } from "@/api/group";

export default {
  components: {
    AppDialog,
    AppMessageBox
  },

  data() {
    return {
      isBtnLoading: false,
      isCreatingNewGroup: false,
      newGroupName: "",
      fieldRules: {
        newGroupName: [val => (val || "").length > 0 || "分组名称不能为空"]
      },
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      groupList: "group/groupList"
    })
  },

  methods: {
    ...mapActions({
      refreshGroupList: "group/refreshGroupList"
    }),
    groupCreationDialogClosed() {
      // 重置控件
      this.newGroupName = "";
    },
    getGroupDetailsRouteName(groupGuid) {
      return {
        name: "configgroupdetails",
        params: { guid: groupGuid }
      };
    },
    pushRouteToGroup(groupGuid) {
      let currentGroupGuid = this.$route.params
        ? this.$route.params.guid
        : null;
      if (!currentGroupGuid || groupGuid !== currentGroupGuid) {
        this.$router.push(this.getGroupDetailsRouteName(groupGuid));
      }
    },
    async createNewGroup() {
      try {
        this.isBtnLoading = true;
        let newGroupGuid = await createNewGroup(
          this.userGuid,
          this.newGroupName
        );
        await this.refreshGroupList();
        if (newGroupGuid) {
          this.$router.push(this.getGroupDetailsRouteName(newGroupGuid));
        }
        this.isCreatingNewGroup = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    }
  },

  async created() {
    try {
      await this.refreshGroupList();
      if (this.groupList && this.groupList.length) {
        this.pushRouteToGroup(this.groupList[0].value);
      }
    } catch (err) {
      this.errorMsg = err.message;
    }
  }
};
</script>
